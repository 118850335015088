<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>Edit Bonus Rate {{ bonusRate.description }}</h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Description"
            v-model="bonusRate.description"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Bonus Rate (£)"
            type="number"
            step="0.01"
            prefix="£"
            v-model="bonusRate.rate"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Material Usage"
            type="number"
            step="0.01"
            v-model="bonusRate.material_usage"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-text-field
            label="Units"
            v-model="bonusRate.units"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-4">
          <v-btn @click="updateBonusRate">Save changes</v-btn>
        </v-col>
      </v-row>
      <v-dialog
        v-model="showMessage"
        max-width="290"
      >
        <v-card>
          <v-card-text class="pt-4">
            {{ message }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange darken-2"
              text
              @click="$router.push('/admin/boq-bonus-rates/index')"
            >
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>  
  </template>
  
  <script>
  import axios from '../../../axios';
  
  export default {
    name: 'AdminBoqBonusRatesEdit',
    computed: {
      token() {
        return this.$store.state.token;
      },
      userid() {
        return this.$store.state.userid;
      },
    },
    data() {
      return {
        bonusRate: {},
        message: '',
        showMessage: false,
      };
    },
    methods: {
      getBonusRate() {
        const bonusRateId = this.$route.params.id;
        axios.get(`/bonusRates/get/${bonusRateId}.json?token=${this.token}`)
          .then((response) => {
            this.bonusRate = response.data.bonusRate;
            this.bonusRate.rate = response.data.bonusRate.rate / 100;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      updateBonusRate() {
        axios.post(`/bonusRates/update.json?token=${this.token}`, this.bonusRate)
          .then((response) => {
            this.message = response.data.message;
            this.showMessage = true;
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
    mounted() {
      this.getBonusRate();
    },
  };
  </script>
  